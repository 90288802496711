import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const CookiesPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="Read the Cookie Policy Below" />
        <p>
        We and selected third parties collect personal information. In case of sale of your personal information, you may opt out by using the link: {" "}
        <a href="https://www.iubenda.com/privacy-policy/61437531" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Do Not Sell My Personal Information</a>
        {" "}To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our privacy policy.
        </p>
      </Layout>
    </>
  )
}

export default CookiesPage
